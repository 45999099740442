import React, { useState } from "react";
import Spinner from "./components/Spinner/Spinner";
import useAxios from "./hooks/useAxios";
import ShowPokemonDetails from "./components/ShowPokemonDetails";
import "./App.css";

function PokemonAxios() {
    const [pokemon, setPokemon] = useState("Pikachu");

    const [setUrl, data, loading, setLoading, error] = useAxios();

    function handleOnSubmit(e){
        e.preventDefault();

        setUrl(`https://pokeapi.co/api/v2/pokemon/${pokemon}`);
        setLoading(true);
    }

    return ( 
        <div className="App" >
            <h1>A Pokemon Search app!</h1>
            <form onSubmit={handleOnSubmit} >
                <input 
                    type="text"
                    onChange={(e) => setPokemon(e.target.value)}
                />
                <button type="submit" >
                    Submit
                </button>
            </form>

            {/* Only shows is loading is true */}
            {loading && <Spinner />}
            {/* if loading is set to false, and the data exists, then it renders the ShowPokemonDetails component */}
            {!loading && data && <ShowPokemonDetails data={data} />}

            {error && <div>Error: {error}</div>}
        </div>
    );
}

export default PokemonAxios;

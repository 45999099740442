function ShowPokemonDetails({ data }){
    const shiny = data.sprites.other.home.front_shiny

    return (
        <div>
            <h1>My Shiny pokemon {data.name}!!!</h1>
            <h2>Pokedex No. {data.id}</h2>
            <img src={shiny} alt={`shiny ${data.name}`} />
        </div>
    )
}

export default ShowPokemonDetails;